import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"

import { useTranslation } from "react-i18next";
import i18n from "i18next";
import Cookies from "js-cookie";

import logo from "../img/logo.png";

import MobileMenu from "./MobileMenu";

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import lock from '../assets/img/lock.svg'

function Header( ) {

    const { t } = useTranslation();

    const [isOpen, setisOpen] = useState(false);

    const languages = [
        { value: 'en', label: 'EN' },
        { value: 'de', label: 'DE' },
        { value: 'ar', label: 'AR' },
        { value: 'ro', label: 'RO' },
        { value: 'pl', label: 'PL' },
        { value: 'ru', label: 'RU' },
      ]
      const storedLang = Cookies.get("i18next");
      const [currentLang, setCurrentLang] = useState(storedLang || 'en');
  
      useEffect(() => {
          if (storedLang && storedLang !== currentLang) {
              setCurrentLang(storedLang);
              i18n.changeLanguage(storedLang);
          }
          // eslint-disable-next-line
      }, [storedLang]);
      
  
      const handleChangeLang = (selectedLang) => {
          setCurrentLang(selectedLang.value);
          i18n.changeLanguage(selectedLang.value);
          Cookies.set("i18next", selectedLang.value);
      };
    const location = useLocation();

    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const position = window.scrollY;
            if (position > 500) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <div className={`header-container transition duration-1000 ${scrolled ? 'bg-white' : 'bg-[#FFFFFF90]'}`}>
            <div className="hidden max-w-[1280px] h-[60px] justify-between mx-auto xl:flex items-center">
                <Link to="/"><img src={logo} alt="" className="w-[40px]"/></Link>
                <Link to="/trading" ><p className={`text-[14px] textcolor roboto ${location.pathname === '/trading' ? 'font-medium' : 'font-light'}`}>{t('header.1')}</p></Link>
                <Link to="/asset" ><p className={`text-[14px] textcolor roboto ${location.pathname === '/asset' ? 'font-medium' : 'font-light'}`}>{t('header.2')}</p></Link>
                <Link to="/community" ><p className={`text-[14px] textcolor roboto ${location.pathname === '/community' ? 'font-medium' : 'font-light'}`}>{t('header.3')}</p></Link>
                <Link to="/support" ><p className={`text-[14px] textcolor roboto ${location.pathname === '/support' ? 'font-medium' : 'font-light'}`}>{t('header.4')}</p></Link>
                <Link to="/about" ><p className={`text-[14px] textcolor roboto ${location.pathname === '/about' ? 'font-medium' : 'font-light'}`}>{t('header.5')}</p></Link>
                <div className="flex items-center gap-[5px]">
                    <Link to="/edu"><p className={`text-[14px] textcolor roboto ${location.pathname === '/about' ? 'font-medium' : 'font-light'}`}>{t('header.edu')}</p></Link>
                    <img src={lock} alt="" />
                </div>
                <div>
                    <div className="flex items-center gap-[10px] cursor-pointer" onClick={() => setisOpen(!isOpen)}>
                        <p className="font-light textcolor text-[14px] roboto">{t('header.7')}</p>
                        <svg className={`${isOpen === true ? 'rotate-90' : 'rotate-0'} transition-all`} xmlns="http://www.w3.org/2000/svg" width="12" height="24" viewBox="0 0 12 24" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.157 12.7116L4.5 18.3686L3.086 16.9546L8.036 12.0046L3.086 7.05463L4.5 5.64062L10.157 11.2976C10.3445 11.4852 10.4498 11.7395 10.4498 12.0046C10.4498 12.2698 10.3445 12.5241 10.157 12.7116Z" fill="black"/>
                        </svg>
                    </div>
                    {isOpen ? 
                        <div className={`${scrolled ? 'bg-white' : 'bg-[#FFFFFF90]'} transition duration-1000 absolute top-[60px] p-[5px]`}>
                            <Link to="/quarterly" onClick={() => setisOpen(false)}><p className="font-light textcolor roboto text-[14px]">{t('header.8')}</p></Link>
                            <Link to="/ipo" onClick={() => setisOpen(false)}><p className="font-light textcolor roboto text-[14px] mt-[5px]">{t('header.9')}</p></Link>
                        </div>
                        :
                        <></>
                    }
                                     
                </div>
                <div className="flex ml-[100px]">
                    <Dropdown
                        options={languages}
                        placeholder="EN"
                        onChange={handleChangeLang}
                        value={languages.find(lang => lang.value === currentLang)}
                        className='mt-[5px] mr-[20px] font-light'
                    />
                    <div className='bg-[#0349DA] w-[124px] h-[39px] flex items-center justify-center rounded-[40px] mt-[5px]'>
                        <Link to='https://user.cgl-limited.net'>
                            <div>
                                <p className='roboto text-[16px] font-normal text-center text-white'>{t('header.6')}</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="xl:hidden flex mx-[20px] items-center justify-between h-[60px]">
                <Link to="/"><img src={logo} alt="" className="w-[30px]"/></Link>
                <MobileMenu/>
            </div>
        </div>
    );
}

export default Header;
