import React, { useState } from "react";

import { useTranslation } from "react-i18next";

const faqData = [
    {
        question: 'investors.ipo.26',
        answer: 'investors.ipo.27'
    },
    {
        question: 'investors.ipo.28',
        answer: 'investors.ipo.29'
    },
    {
        question: 'investors.ipo.30',
        answer: 'investors.ipo.31'
    },
    {
        question: 'investors.ipo.32',
        answer: 'investors.ipo.33'
    },
    {
        question: 'investors.ipo.34',
        answer: 'investors.ipo.35'
    },
]

function Faqs () {
    
    const [openIndex, setOpenIndex] = useState(null);

    const toggleText = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const { t } = useTranslation();

    return(
        <div className="mt-[80px] xl:mt-[160px] pb-[80px] xl:pb-[160px]">
            <div className="max-w-[872px] xl:mx-auto mx-[20px] flex flex-col">
                <p className="open-sans text-[#0349DA] font-[800] text-[16px] xl:text-[30px] mx-auto">{t('investors.ipo.25')}</p>
                {faqData.map((faq, index) => (
                    <div key={index}>
                        <div className={`flex justify-between mt-[20px] h-[60px] transition-all duration-300 ${openIndex === index ? 'h-auto bg-[#FFF]' : 'bg-[#FFF]'}`}>
                            <div>
                                <div className="flex items-center">
                                    <div className="w-[60px] h-[60px] flex items-center justify-center bg-[#D1DEF8]">
                                        <div className='cursor-pointer flex mx-[20px]' onClick={() => toggleText(index)}>
                                            <svg className={`${openIndex === index ? 'transition-transform -rotate-45' : 'transition-transform -rotate-90'}`} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <g clip-path="url(#clip0_300_2776)">
                                                    <path d="M19.2188 9.21875H10.7812V0.78125C10.7812 0.349766 10.4315 0 10 0C9.56852 0 9.21875 0.349766 9.21875 0.78125V9.21875H0.78125C0.349766 9.21875 0 9.56852 0 10C0 10.4315 0.349766 10.7812 0.78125 10.7812H9.21875V19.2188C9.21875 19.6502 9.56852 20 10 20C10.4315 20 10.7812 19.6502 10.7812 19.2188V10.7812H19.2188C19.6502 10.7812 20 10.4315 20 10C20 9.56852 19.6502 9.21875 19.2188 9.21875Z" fill="#001F61"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_300_2776">
                                                        <rect width="20" height="20" fill="white"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </div>
                                    <p className='manrope font-medium text-[14px] xl:text-[16px] text-[#001F61] ml-[40px]'>{t(faq.question)}</p>
                                </div>
                                {openIndex === index && (
                                <div className="flex">
                                    <div className="min-w-[60px] bg-[#D1DEF8]"></div>
                                    <p className='textcolor roboto text-[14px] xl:text-[16px] font-light mt-[12px] pb-[20px] ml-[40px]'>{t(faq.answer)}</p>
                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Faqs;